<template>
  <div class="page-inner">
    <MainMenu />

    <div class="main-page">
      <Account />
      <div class="w-full pt-10 space-y-3">
        <div class="w-full flex items-center space-x-4">
          <h3 class="main-color font-semibold text-4xl">
            Your campaign status
          </h3>
        </div>

        <div class="md:flex w-full justify-between">
          <LineChart
            v-if="loaded"
            :key="$route.params.id"
            :styles="myStyles"
            :chartData.sync="chartdata"
            :options="chartOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainMenu from "../common/MainMenu";
import Account from "../common/Account";
import LineChart from "../common/charts/line";
export default {
  name: "Dashboard",
  components: { MainMenu, LineChart, Account },
  data: () => ({
    loaded: false,
    chartOptions: {
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      height: 600,
      width: "100%",
    },
    chartdata: null,
  }),
  mounted() {
    this.getCampaigns();
  },
  computed: {
    myStyles() {
      return {
        height: `600px`,
        width: `100%`,
        position: "relative",
      };
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.getCampaigns();
    next();
  },
  methods: {
    async getCampaigns() {
      this.loaded = false;
      try {
        const response = await this.$getStats(this.$route.params.id);
        if (response.data.data)
          this.chartdata = {
            labels: response.data.data.map((el) => {
              return el._id;
            }),
            datasets: [
              {
                label: "Users",
                borderDash: [5, 5],
                fill: false,
                borderColor: "#2329d6",
                data: response.data.data.map((el) => {
                  return el.count;
                }),
              },
            ],
          };
        else
          this.chartdata = {
            labels: [],
            datasets: [
              {
                label: "Users",
                borderDash: [5, 5],
                fill: false,
                borderColor: "#2329d6",
                data: [],
              },
            ],
          };
        console.log("   this.chartdata", this.chartdata);
        this.loaded = true;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
