import Vue from 'vue'
import Vuex from 'vuex'
import campaigns from './modules/campaigns'
import formbuilder from './modules/formbuilder'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    campaigns,
    formbuilder

  },
  strict: debug,
})
