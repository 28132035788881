import Vue from "vue";

import App from "./App.vue";
import i18n from "@/plugins/i18n";
import API from "@/plugins/api";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);
import "./assets/css/import.css";
import "./assets/css/custom.scss";

import store from "./store";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en"; // Default lang is Chinese
import VueLodash from "vue-lodash";
Vue.use(VueLodash);
Vue.use(Element, { locale });

import Axios from "axios";
const token = localStorage.getItem("user-token");
import router from "./router";

Vue.prototype.$http = Axios;
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

Vue.config.productionTip = false;
Vue.config.productionTip = false;
Vue.use(API);
var vm = new Vue({
  router,
  i18n,
  API,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vm;
