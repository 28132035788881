<template>
  <div class="md:grid md:grid-cols-3 gap-6 py-12">
    <div class="box-build-form">
      <h4>CONTACT FORM</h4>
      <img src="/assets/images/icons/contact.svg" />
      <router-link
        class="main-btn-outline"
        to="/create/f909e457-1722-4711-9711-ee5e3f0f7eb0"
        >Use this template
      </router-link>
    </div>
    <div class="box-build-form">
      <h4>QUIZ</h4>
      <img src="/assets/images/icons/quiz.svg" />
      <router-link
        class="main-btn-outline"
        to="/create/6a3f1452-b0fc-4d4b-814b-789851dc5817"
        >Use this template
      </router-link>
    </div>
    <div class="box-build-form">
      <h4>NEWSLETTER SUBSCRIPTION</h4>
      <img src="/assets/images/icons/newsletter.svg" />
      <router-link
        class="main-btn-outline"
        to="/create/531bfce6-020e-4b15-8328-72adcac618c5"
        >Use this template
      </router-link>
    </div>
    <div class="box-build-form">
      <h4>EVENT REGISTRATION</h4>
      <img src="/assets/images/icons/event.svg" />
      <router-link
        class="main-btn-outline"
        to="/create/20505bcc-da91-47dd-adea-eb6fb6fb03d0"
        >Use this template
      </router-link>
    </div>
    <div class="box-build-form">
      <h4>APPOINTMENT</h4>
      <img src="/assets/images/icons/appointment.svg" />
      <router-link
        class="main-btn-outline"
        to="/create/0c7da82d-e09f-41a6-beca-fdbda03816f1"
        >Use this template
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
