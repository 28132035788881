<template>
  <div>
    <Header textLink="Register" link="/register" />
    <div class="section overflow-hidden">
      <div class="container mt-3xl mb-3xl">
        <div class="row">
          <div class="column align-center">
            <h1 class="test-giga text-center">
              Discover how easy it is to get more leads
            </h1>
            <p class="text-lg text-center max-w-lg">
              Build your own form, survey or quiz in minutes.
            </p>
            <router-link to="/register" class="button xl main mt-lg w-button"
              >Get started for free</router-link
            >
            <div class="text-sm mt-md muted">No credit card required</div>
          </div>
        </div>
      </div>
      <div class="circle"></div>
    </div>
    <div class="section">
      <img
        src="/images/product-shot.png"
        srcset="
          images/product-shot-p-500.png   500w,
          images/product-shot-p-800.png   800w,
          images/product-shot-p-1080.png 1080w,
          images/product-shot.png        1521w
        "
        sizes="(max-width: 479px) 93vw, (max-width: 767px) 94vw, 720px"
        data-w-id="a4177896-a5e5-6c6a-0980-01ce8ea2054a"
        alt=""
        class="product-shot"
      />
    </div>
    <div class="section">
      <div class="container mt-2xl mb-2xl">
        <div class="row">
          <div class="column align-center">
            <h2 class="max-w-lg text-center">
              Create your own online survey and form in minutes
            </h2>
            <p class="text-lg text-center max-w-md">
              Create a professional, customizable form and survey online with
              AnyWebForm and get the most out of your customer survey.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row items-center v-t">
          <div class="column align-left p-2xl">
            <h2 class="max-w-lg">
              Are you tired of spending countless hours creating and editing
              forms?
            </h2>
            <p class="text-base">
              With our drag and drop builder, you can create beautiful online
              forms in minutes. You can even embed your forms on your website or
              blog.
              <br />
              Our form builder makes it easy to customize the look and feel of
              your forms. Use our pre-designed templates or create a custom
              design from scratch. No coding or technical knowledge required!
              <br />

              Start saving time and money when building online forms for you &
              your business!
              <br />

              Start creating your online form today!
            </p>
          </div>
          <div class="column align-center p-2xl">
            <img
              src="/images/marginalia-coming-soon.png"
              alt=""
              class="feature-card"
            />
          </div>
        </div>
        <div class="row reverse items-center v-t">
          <div class="column align-left p-2xl">
            <h2 class="max-w-lg">Do you need a survey?</h2>
            <p class="text-base">
              Our online survey software will help you build and publish
              beautiful online forms and surveys in minutes.
              <br />
              You can create unlimited surveys, forms and quizzes with unlimited
              questions, answers, responses and more! It's perfect for
              collecting feedback from customers, clients or employees.
              <br />
              <br />
              <router-link to="/register" class="u mr-lg"
                >Create your first survey!</router-link
              >
            </p>
          </div>
          <div class="column align-center p-2xl">
            <img
              src="/images/marginalia-online-shopping.png"
              alt=""
              class="feature-card"
            />
          </div>
        </div>
        <div class="row items-center v-t">
          <div class="column align-left p-2xl">
            <h2 class="max-w-lg">You’re in charge of your online data</h2>
            <p class="text-base">
              AnyWebForm allows you to manage collected data, see the responses
              and generate reports. so you can easily track your campaign
              progress and performance... and we have a free plan for up to
              10000 responses per month.
              <br />
              <br />
              <router-link to="/register" class="u mr-lg"
                >Try it for free!</router-link
              >
            </p>
          </div>
          <div class="column align-center p-2xl">
            <img
              src="/images/marginalia-order-complete.png"
              alt=""
              class="feature-card"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section main mt-2xl">
      <div class="container mt-2xl mb-2xl">
        <div class="row items-center">
          <div class="column align-center">
            <h1 class="max-w-lg text-giga text-center text-white">
              Start for free. Build anything you want.
            </h1>
            <router-link
              to="/register"
              class="button xl main white mt-lg w-button"
              >Get started for free</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        class="
          md:flex
          md:w-11/12
          mx-auto
          pt-20
          items-start
          justify-between
          space-x-4
        "
      >
        <div class="pricing">
          <h5 class="main-color">Free</h5>
          <router-link class="main-btn" to="/register">Start now </router-link>
          <p>no credit card required</p>
          <ul>
            <li>1000 responses per month</li>
            <li>Basic reports</li>
            <li>Unlimited forms</li>
            <li>Unlimited questions and steps</li>
          </ul>
        </div>
        <div class="pricing">
          <h5>
            <span class="color-1"
              ><span class="font-bold text-3xl">15</span> &euro;</span
            >
            / month
          </h5>
          <router-link class="main-btn" to="/register">Coming Soon</router-link>
          <p>and get a discount</p>
          <ul>
            <li class="color-1 font-bold">10 000 responses per month</li>
            <li class="color-1 font-bold">Advanced reports</li>
            <li>Unlimited questions and steps</li>
            <li>Multiple embed options</li>
          </ul>
        </div>
        <div class="pricing">
          <h5>Enterprise</h5>
          <router-link class="main-btn-outline" to="/register"
            >Contact us</router-link
          >
          <p>&nbsp;</p>
          <ul>
            <li>Unlimited responses per month</li>
            <li>Unlimited forms</li>
            <li>Unlimited questions and steps</li>
            <li>Encrypted data storage and</li>
            <li>Built in AI (Predictive Analytics)</li>
            <li>Custom form types and engagements</li>
            <li>Custom subdomain</li>
          </ul>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import "../../assets/css/normalize.css";
import "../../assets/css/custom.scss";
import "../../assets/css/lander-409e35.webflow.css";

import Header from "../common/auth/header";
import Footer from "../common/auth/footer";

export default {
  name: "Index",
  components: {
    Header,
    Footer,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
