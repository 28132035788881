<template>
  <div>
    <Header textLink="Register" link="/register" />
    <form class="register-form" @submit.prevent="loginUser">
      <div class="container flex h-screen-calc items-center">
        <div class="w-full space-y-8 px-4 md:px-0">
          <div class="w-full md:flex">
            <div class="w-full md:w-6/12">
              <h2 class="main-color text-4xl md:text-9xl font-bold">
                {{ $t("HELLO") }}
              </h2>
            </div>
            <div class="w-full md:w-6/12">
              <h3 class="main-color font-semibold text-2xl">
                Enter your login details
              </h3>
              <form-input
                :placeholder="$t('Email')"
                v-model="values.email"
                type="email"
                beforeClass="email-input"
                @validate="validate('email')"
                name="email"
                :error="errors.email"
              ></form-input>

              <form-input
                :placeholder="$t('Password')"
                v-model="values.password"
                type="password"
                beforeClass="password-input"
                @validate="validate('password')"
                name="password"
                :error="errors.password"
              ></form-input>
              <!-- <div class="row flex align-items-center">
            <div class="col-6 align-items-center">
              <router-link to="/forgot-password">Forgot password?</router-link>
            </div>
            <div class="col-6">
            
            </div>
          </div> -->
            </div>
          </div>

          <div class="space-y-2">
            <div class="w-full items-center flex justify-center px-5 flex-wrap">
              <button class="main-btn is-big">Login to your account</button>
            </div>
            <div class="w-full items-center flex justify-center px-5 flex-wrap">
              <router-link to="/forgot-password" class="link"
                >Forgot password?</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </form>
    <Footer />
  </div>
</template>

<script>
import { object, string } from "yup";
import FormInput from "../common/form/FormInput";

import Header from "../common/auth/header";
import Footer from "../common/auth/footer";

export default {
  name: "Login",
  components: {
    "form-input": FormInput,
    Header,
    Footer,
  },
  data() {
    return {
      values: {
        email: "",
        password: "",
      },
      errors: {
        email: "",
        password: "",
      },
      loginFormSchema: object().shape({
        email: string()
          .email(this.$t("ErrorInvalidEmail"))
          .required(this.$t("ErrorIsRequired")),
        password: string()
          .min(2, this.$t("ErrorMinLength", { c: 6 }))
          .required(this.$t("ErrorIsRequired")),
      }),
    };
  },
  methods: {
    async submit(e) {
      try {
        const response = await this.$useLogin(e);
        if (response && response.data && response.data.success === true) {
          // go to step 2
          this.$logUserIn(response.data.token);

          this.$router.push("/dashboard");
        } else {
          this.$toast.error(this.$t(response.data.error), {
            position: "bottom-left",
          });
        }
      } catch (error) {
        console.log("submit", error);
      }
    },
    loginUser() {
      var self = this;
      this.loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async (e) => {
          self.submit(e);
          // self.$toast.error("your message");
        })
        .catch((err) => {
          if (err.inner)
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
        });
    },
    validate(field) {
      this.loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>

<style>
</style>
