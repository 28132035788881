<template>
  <div>
    <Header />
    <div class="page main-signin-wrapper">
      <!-- Row -->

      <div class="row flex">
        <div class="col-lg-6 mx-auto">
          <div class="text-center mb-2"></div>
          <div class="card custom-card">
            <div class="card-body">
              <Steps step="3" />
              <Step3 />
            </div>
          </div>
        </div>
      </div>
      <!-- End Row -->
    </div>
  </div>
</template>

<script>
import Header from "../common/auth/header";
import Steps from "../common/auth/Steps";
import Step3 from "./register/Step3";

export default {
  name: "RegisterOnboarding",
  components: {
    Header,
    Steps,
    Step3,
  },
  data() {
    return {
      step: 2,
      user: false,
    };
  },
  async mounted() {
    const user = await this.$getUser();
    if (!user.status || user.status === "active") {
      this.user = user;
    } else {
      self.$router.push("/register/validate");
    }
  },
  methods: {
    gotoStep(i) {
      this.step = i;
    },
  },
};
</script>

<style scoped>
.wizard {
  border: 0;
}
.wizard > .steps a .number,
.wizard > .steps a:hover .number,
.wizard > .steps a:active .number {
  width: 45px;
  height: 45px;
  font-size: 18px;
  line-height: 45px;
}
</style>>
