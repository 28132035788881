<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options", "chartdata"],
  data() {
    return {
      gradient: null,
      htmlLegend: null,
    };
  },
  mounted() {
    // this.gradient = this.$refs.canvas
    //   .getContext("2d")
    //   .createLinearGradient(0, 0, 0, 450);
    // this.gradient.addColorStop(0, "rgba(52, 144, 220, 0.6)");
    // this.gradient.addColorStop(0.5, "rgba(52, 144, 220, 0.25)");
    // this.gradient.addColorStop(1, "rgba(52, 144, 220, 0)");

    // this.gradient = this.$refs.canvas
    //   .getContext("2d")
    //   .createLinearGradient(0, 0, 0, 450);
    // this.gradient.addColorStop(0, "rgba(52, 144, 220, 0.6)");
    // this.gradient.addColorStop(0.5, "rgba(52, 144, 220, 0.25)");
    // this.gradient.addColorStop(1, "rgba(52, 144, 220, 0)");

    // if(this.chartData.datasets[0].backgroundColor){

    //   this.chartData.datasets[0].backgroundColor = this.gradient;
    // }
    this.renderChart(this.chartData, this.options);
    // this.renderChart(this.chartData, this.options);
  },
};
</script>
