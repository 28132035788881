<template>
  <div>
    <Header textLink="Login" link="/login" />
    <form class="register-form" @submit.prevent="loginUser">
      <div class="container flex h-screen-calc items-center">
        <div class="w-full space-y-8">
          <div class="w-full flex">
            <div class="w-full md:w-6/12">
              <h2 class="main-color text-9xl font-bold">HELLO</h2>
            </div>
            <div class="w-full md:w-6/12">
              <h3 class="main-color font-semibold text-2xl">
                {{ $t("ChangePassword") }}
              </h3>
              <form-input
                :placeholder="$t('Password')"
                v-model="values.password"
                type="password"
                @validate="validate('password')"
                name="password"
                beforeClass="email-input"
                :error="errors.password"
              ></form-input>

              <form-input
                :placeholder="$t('RetypePassword')"
                v-model="values.confirm_password"
                type="password"
                @validate="validate('confirm_password')"
                name="confirm_password"
                beforeClass="email-input"
                :error="errors.confirm_password"
              ></form-input>
              <!-- <div class="row flex align-items-center">
            <div class="col-6 align-items-center">
              <router-link to="/forgot-password">Forgot password?</router-link>
            </div>
            <div class="col-6">
            
            </div>
          </div> -->
            </div>
          </div>

          <div class="space-y-2">
            <div class="w-full items-center flex justify-center px-5 flex-wrap">
              <button class="main-btn is-big">Change password</button>
            </div>
            <div class="w-full items-center flex justify-center px-5 flex-wrap">
              <router-link to="/login" class="link">Login</router-link>
            </div>
          </div>
        </div>
      </div>
    </form>
    <Footer />
  </div>
</template>

<script>
import { object, string, ref } from "yup";
import FormInput from "../common/form/FormInput";
import Footer from "../common/auth/footer";

import Header from "../common/auth/header";

export default {
  name: "Login",
  components: {
    "form-input": FormInput,
    Header,
    Footer,
  },
  data() {
    return {
      values: {
        password: "",
        confirm_password: "",
        code: "",
      },
      errors: {
        password: "",
        confirm_password: "",
      },
      loginFormSchema: object().shape({
        password: string()
          .min(2, this.$t("ErrorMinLength", { c: 6 }))
          .required(this.$t("ErrorIsRequired")),
        confirm_password: string().oneOf(
          [ref("password"), null],
          this.$t("ErrorPasswordMatch")
        ),
      }),
    };
  },
  created() {
    this.values.code = this.$route.params.id;
  },
  methods: {
    async submit(e) {
      try {
        const response = await this.$resetPassword(e);
        if (response && response.data && response.data.success === true) {
          this.$toast.success(this.$t("PasswordChanged"), {
            position: "bottom-left",
          });
          this.$router.push("/register/validate");
        } else {
          this.$toast.error(this.$t(response.data.error), {
            position: "bottom-left",
          });
        }
      } catch (error) {
        console.log("submit", error);
      }
    },
    loginUser() {
      var self = this;
      this.loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async (e) => {
          self.submit(e);
          // self.$toast.error("your message");
        })
        .catch((err) => {
          if (err.inner)
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
        });
    },
    validate(field) {
      this.loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>

<style>
</style>
