<template>
  <form class="register-form" @submit.prevent="registerUser">
    <div class="container flex h-screen-calc items-center">
      <div class="flex w-full">
        <div class="w-full space-y-8 pt-20 px-4 md:px-0">
          <div class="w-full md:flex">
            <div class="w-full md:w-6/12">
              <h2
                class="main-color text-4xl md:text-9xl font-bold remove-space"
              >
                {{ $t("HELLO") }}
              </h2>
              <!-- <p>Lorem Ipsum</p> -->
              <!-- <form-input
                :label="'First name'"
                :placeholder="$t('What’s your name')"
                v-model="values.firstName"
                type="text"
                @validate="validate('firstName')"
                name="firstName"
                :error="errors.firstName"
              ></form-input> -->
            </div>
            <div class="w-full md:w-6/12">
              <form-input
                :placeholder="$t('Email')"
                v-model="values.email"
                type="text"
                beforeClass="email-input"
                @validate="validate('email')"
                name="email"
                :error="errors.email"
              ></form-input>
              <form-input
                :placeholder="$t('Password')"
                v-model="values.password"
                type="password"
                beforeClass="password-input"
                @validate="validate('password')"
                name="password"
                :error="errors.password"
              ></form-input>

              <div class="form-group">
                <input
                  type="checkbox"
                  id="agree"
                  v-model="values.agree"
                /><label for="agree"
                  ><span></span>
                  <p>
                    I agree to anywebform’s
                    <router-link to="/" target="_blank"
                      >Terms of Service</router-link
                    >
                  </p></label
                >
                <p
                  class="invalid-feedback"
                  v-if="!values.agree && hasSubminted"
                >
                  {{ $t("ErrorIsRequired") }}
                </p>
              </div>
              <div class="form-group">
                <input
                  type="checkbox"
                  id="data-service"
                  v-model="values.service"
                /><label for="data-service"
                  ><span></span>
                  <p>
                    I accept Anywebform’s use of my data for the service and
                    everything else described in the
                    <router-link to="/" target="_blank"
                      >Privacy Policy</router-link
                    >
                  </p></label
                >
                <p
                  class="invalid-feedback"
                  v-if="!values.service && hasSubminted"
                >
                  {{ $t("ErrorIsRequired") }}
                </p>
              </div>
            </div>
          </div>

          <div class="space-y-2">
            <div class="w-full items-center flex justify-center px-5 flex-wrap">
              <button class="main-btn is-big">Continue</button>
            </div>
            <div class="w-full items-center flex justify-center px-5 flex-wrap">
              <router-link to="/login" class="link"
                >Login to your account</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { object, string } from "yup";
import FormInput from "../../common/form/FormInput";

export default {
  name: "RegisterStep1",
  components: {
    "form-input": FormInput,
  },
  data() {
    return {
      values: {
        firstName: "First name",
        lastName: "",
        email: "",
        agree: false,
        service: false,
        password: "",
        confirm_password: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirm_password: "",
        agree: "",
        service: "",
      },
      hasSubminted: false,

      loginFormSchema: object().shape({
        firstName: string()
          .min(2, this.$t("ErrorMinLength", { c: 2 }))
          .required(this.$t("ErrorIsRequired")),

        email: string()
          .email(this.$t("ErrorInvalidEmail"))
          .required(this.$t("ErrorIsRequired")),
        password: string()
          .min(2, this.$t("ErrorMinLength", { c: 6 }))
          .required(this.$t("ErrorIsRequired")),
        // confirm_password: string().oneOf(
        //   [ref("password"), null],
        //   // [this.values.password, null],
        //   this.$t("ErrorPasswordMatch")
        // ),
      }),
    };
  },
  methods: {
    registerUser() {
      var self = this;
      this.hasSubminted = true;
      if (!this.values.agree || this.validate.service) {
        return false;
      }
      this.loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async (e) => {
          const response = await self.$useRegister(e);
          if (response.data.success === true) {
            // go to step 2
            self.$logUserIn(response.data.token);
            self.$router.push("/register/validate");
          } else {
            self.$toast.error(self.$t(response.data.error), {
              position: "bottom-left",
            });
          }

          // self.$toast.error("your message");
        })
        .catch((err) => {
          console.log(err);
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    validate(field) {
      this.loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>

<style scoped>
</style>>
