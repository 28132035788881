<template>
  <header class="nav">
    <div class="container">
      <div class="row items-center mb-lg">
        <div class="column align-left">
          <router-link to="/" class="">
            <div class="logo">
              <img src="/logo.svg" style="width: 48px" />
              <h1 class="text-base font-bold ml-1">
                <span>any<strong class="main-color">webform</strong></span>
              </h1>
            </div>
          </router-link>
        </div>
        <div class="column align-right">
          <div class="row items-center">
            <router-link to="/login" class="u mr-lg">Log in</router-link>
            <router-link to="/register" class="button main w-button"
              >Sign in<span
                data-feather="arrow-right"
                class="icon mr-md-n ml-md"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right icon mr-md-n ml-md"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline></svg></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["link", "textLink"],
  name: "Header",
};
</script>

<style>
</style>
