import lodash from 'lodash'

const state = () => ({
  forms: [],
  activeField: [],
  activeTabForFields: 'elements'
})


// getters
const getters = {
  getForms: (state) => {
    return state.forms
  },

  getActiveField: (state) => {
    return state.activeField
  },
  getSectionField: (state) => (index) => {
    return state.forms[index].fields
  },
  getActiveTabForFields: (state) => {
    return state.activeTabForFields
  }
}

// actions
const actions = {

  initiate({ commit }, items) {
    commit('initiate', { items: items })

  },
  setItems({ commit }, items) {
    commit('setFormItems', { items: items })

  },
  deleteItem({ commit }, items) {
    commit('deleteFormItems', { items: items })

  },
  deleteFormElement({ commit }, items) {
    commit('deleteFormElement', { items: items })

  },

  cloneItem({ commit }, items) {
    commit('cloneItem', { payload: items })

  },



  setActiveField({ commit }, items) {
    commit('setActiveField', { items: items })

  },

  setFormData({ commit }, items) {
    commit('setFormData', { items: items })

  },

  setFormFieldInfo({ commit }, items) {
    commit('setFormFieldInfo', { items: items })

  },


  setFormTitle({ commit }, items) {
    commit('setFormTitle', { items: items })

  },
  setActiveTabForFields({ commit }, items) {
    commit('setActiveTabForFields', { items: items })
  },
  addFormFields({ commit }, items) {
    commit('addFormFields', { items: items })
  },

}

// mutations
const mutations = {

  initiate(state, { items }) {
    state.forms = items
  },
  setFormItems(state, { items }) {
    const r = [...state.forms, items]
    state.forms = r;
    return state;
  },
  addFormFields(state, { items }) {
    // const r = [...state.forms, items]
    let i = 0;
    for (const item of items.fields) {
      item.id = Math.random() * (1000000 - 1) + 1;
      item.sectionId = items.index;
      item.indexId = i;
      i++;
    }
    state.forms[items.index].fields = items.fields
  },
  setFormFieldInfo(state, { items }) {
    if (state.forms[items.data.sectionId]) {
      let arr = lodash.cloneDeep(state.forms[items.data.sectionId].fields);
      arr[items.data.indexId] = items.data;
      state.forms[items.data.sectionId].fields = arr;
    }

  },

  setFormTitle(state, { items }) {
    // const r = [...state.forms, items]
    state.forms[items.index].title = items.title
  },
  setFormData(state, { items }) {
    const r = []
    for (const item of items.toUpdate) {
      r.push({
        title: item.item.title,
        fields: item.item.fields
      })
    }

    state.forms = r;
  },

  deleteFormItems(state, { items }) {
    const r = [...state.forms];
    r.splice(items, 1);
    state.forms = r;
    return state;
  },
  deleteFormElement(state, { items }) {

    const sections = lodash.cloneDeep(state.forms);
    let i = lodash.cloneDeep(sections[items.formIndex]);
    i.fields.splice(items.index, 1);
    sections[items.formIndex] = i
    state.forms = sections;
  },

  cloneItem(state, { payload }) {
    try {

      const sections = state.forms;
      let items = lodash.cloneDeep(sections[payload.formIndex].fields);
      let item = lodash.cloneDeep(items[payload.index]);

      item.id = Math.random() * (1000000 - 1) + 1;
      item.indexId = items.length;

      items.push(item)
      console.log('item', item)
      sections[payload.formIndex].fields = items;
      state.forms = sections;
    } catch (error) {
      console.log(error)
    }
    // sections[payload.formIndex].fields = items;
    // console.log('sections', sections)
    // state.forms = sections;
    //this.lodash.cloneDeep
  },



  setActiveField(state, { items }) {
    state.activeField = items;
  },
  setActiveTabForFields(state, { items }) {
    state.activeTabForFields = items.val || items
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  strict: false

}