<template>
  <div>
    <h3>{{ currentField.headline }}</h3>
    <p>{{ currentField.textheadline }}</p>
  </div>
</template>

<script>
export default {
  name: "ThankYouComponent",
  props: ["currentField"],
};
</script>
