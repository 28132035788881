import Axios from "axios";
import VueJwtDecode from "vue-jwt-decode";

//const basePath = 'http://localhost:9622/';

export default {
  install(Vue, options) {
    console.log("options", options);
    let basePath = "https://api.siteextender.com/";
    // basePath = "http://localhost:9622/";

    if (window.location.href.match("localhost")) {
      basePath = "http://localhost:9622/";
      basePath = "https://api.siteextender.com/";
    }

    // const basePath = 'https://api.siteextender.com/';
    const optionsAxios = {
      baseURL: basePath,
      timeout: 10000,
    };
    const client = Axios.create(optionsAxios);

    Vue.prototype.$getUser = async () => {
      let token = localStorage.getItem("user");
      let decoded = VueJwtDecode.decode(token);
      return decoded;
    };
    Vue.prototype.$getUserData = async () => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "get",
          url: `user/getdata`,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$logUserOut = () => {
      localStorage.removeItem("user");
      // Vue.$router.push("/");
    };
    Vue.prototype.$logUserIn = (token) => {
      localStorage.setItem("user", token);
    };

    Vue.prototype.$validateCode = async (methodOptions) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "post",
          url: `user/validate`,
          data: {
            code: methodOptions,
          },
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };

    Vue.prototype.$getAllUsers = async (campaignId, startDate, endDate) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "get",
          url: `campaign/data/${campaignId}/1?startDate=${startDate}&endDate=${endDate}`,
          data: {
            startDate: startDate,
            endDate: endDate,
          },
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$getStats = async (campaignId) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "get",
          url: `campaign/stats/${campaignId}`,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };

    Vue.prototype.$getInfo = async (campaignId, id) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "get",
          url: `campaign/getinfo/${campaignId}/${id}`,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$getCampaignFlow = async (campaignId) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "get",
          url: `campaign/getflow/${campaignId}`,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$saveFlow = async (flow, campaignId) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "post",
          url: `campaign/saveflow/${campaignId}`,
          data: { flow },
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$createFromCampaign = async (uuid) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "post",
          url: `campaign/createfrom/${uuid}`,
          data: uuid,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$createCampaign = async (methodOptions) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        const result = await client({
          method: "post",
          url: `campaign/create`,
          data: methodOptions,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };

    Vue.prototype.$resetPassword = async (methodOptions) => {
      try {
        const result = await client({
          method: "post",
          url: `auth/changepassword`,
          data: methodOptions,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$recoverEmail = async (methodOptions) => {
      try {
        const result = await client({
          method: "post",
          url: `auth/recover`,
          data: methodOptions,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$useLogin = async (methodOptions) => {
      try {
        const result = await client({
          method: "post",
          url: `auth/login`,
          data: methodOptions,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };

    Vue.prototype.$exportAll = async (campaignId) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;

        const result = await client({
          method: "get",
          url: `campaign/export/${campaignId}`,
        });
        return result.data;
      } catch (error) {
        console.log("error", error.response);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          window.top.location = "/";
        }
      }
    };
    Vue.prototype.$getUserCampaigns = async () => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;

        const result = await client({
          method: "get",
          url: `campaign/mycampaigns`,
        });
        return result.data;
      } catch (error) {
        console.log("error", error.response);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          window.top.location = "/";
        }
      }
    };
    Vue.prototype.$getUserCampaign = async (id) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;

        const result = await client({
          method: "get",
          url: `campaign/id/${id}`,
        });
        return result.data;
      } catch (error) {
        console.log("error", error);
      }
    };

    Vue.prototype.$createCampaign = async () => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;

        const result = await client({
          method: "post",
          url: `campaign/create/`,
          data: {},
        });
        return result.data;
      } catch (error) {
        console.log("error", error);
      }
    };
    Vue.prototype.$updateCampaign = async (values, id, file) => {
      try {
        if (localStorage.getItem("user"))
          client.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("user")}`;
        console.log(values);
        let formData = new FormData();
        for (const item in values) {
          formData.append(item, values[item]);
        }
        if (file) formData.append("file", file);
        const result = await client({
          method: "post",
          url: `campaign/update/${id}`,
          data: formData,
        });
        return result.data;
      } catch (error) {
        console.log("error", error);
      }
    };

    Vue.prototype.$useRegister = async (methodOptions) => {
      try {
        const result = await client({
          method: "post",
          url: `user/register`,
          data: methodOptions,
        });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    };
  },
};
