<template>
  <div class="page-inner">
    <MainMenu />
    <div class="main-content side-content pt-0"></div>
  </div>
</template>

<script>
import MainMenu from "../common/MainMenu";

export default {
  name: "EditCampaign",
  components: { MainMenu },
  async mounted() {
    const data = await this.$createFromCampaign(this.$route.params.id);
    this.$router.push(`/campaign/${data.data.id}`);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
