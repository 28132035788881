<template>
  <div class="mainmenu">
    <div class="fixed xl:hidden w-full bg-white z-50">
      <div class="flex px-2 justify-between w-full items-center">
        <router-link :to="`/dashboard/${this.$route.params.id}`">
          <div class="flex center-items items-center content-center">
            <img src="/logo.svg" style="width: 48px" />
            <h1 class="text-base font-bold ml-1">
              <span>any<strong class="main-color">webform</strong></span>
            </h1>
          </div>
        </router-link>
        <a @click="openMenu" class="toggleMenu"
          ><span></span><span></span><span></span
        ></a>
      </div>
    </div>
    <div
      :class="`main-sidebar px-10 py-10 main-sidebar-sticky side-menu ${
        menu ? 'fixed' : 'hidden'
      } xl:fixed xl:block`"
    >
      <router-link
        :to="`/dashboard/${this.$route.params.id}`"
        class="hidden xl:block"
      >
        <div class="flex center-items items-center content-center">
          <img src="/logo.svg" style="width: 48px" />
          <h1 class="text-base font-bold ml-1">
            <span>any<strong class="main-color">webform</strong></span>
          </h1>
        </div>
      </router-link>

      <div class="main-sidebar-body">
        <select v-model="campaign" class="form-control-select">
          <option disabled value="">Select a campaign</option>
          <option
            :key="index"
            v-for="(item, index) of campaigns"
            :value="item.uuid"
          >
            {{ item.name }}
          </option>
        </select>
        <ul class="nav-left" v-if="this.$route.params.id">
          <li class="nav-item">
            <router-link
              :to="`/dashboard/${this.$route.params.id}`"
              class="nav-link with-sub"
            >
              <img src="../../assets/images/home.svg" /> Dashboard
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/campaign/stats/${this.$route.params.id}`"
              class="nav-link with-sub"
            >
              <img src="../../assets/images/stats.svg" /> Stats
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/campaign/${this.$route.params.id}`"
              class="nav-link with-sub"
            >
              <img src="../../assets/images/edit.svg" /> Edit
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/campaign/data/${this.$route.params.id}`"
              class="nav-link with-sub"
            >
              <img src="../../assets/images/data.svg" /> Data
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="`/campaign/integration/${this.$route.params.id}`"
              class="nav-link with-sub"
            >
              <img src="../../assets/images/integration.svg" />Integration
            </router-link>
          </li>
          <li class="nav-item block md:hidden mt-14">
            <a @click="logout"> Logout </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainMenu",

  props: {
    msg: String,
  },
  data() {
    return {
      menu: false,
      campaign: "",
    };
  },
  methods: {
    openMenu() {
      this.menu = !this.menu;
    },
    logout() {
      this.$logUserOut();
      this.$router.push("/login");
    },
  },
  watch: {
    campaign() {
      if (this.$route.params.id) {
        if (this.$route.params.id !== this.campaign) {
          let uri = this.$route.path.replace(
            this.$route.params.id,
            this.campaign
          );
          this.$router.push(uri);
        }
      } else {
        if (this.campaign)
          this.$router.push(`/campaign/stats/${this.campaign}`);
      }
      this.$store.dispatch("campaigns/setCurrentCampaign", this.campaign);
    },
  },
  computed: {
    ...mapGetters("campaigns", {
      campaigns: "listCampaigns",
      currentCampaign: "getCurrentCampaign",
    }),
  },
  created() {
    this.campaign = this.$route.params.id;
  },
};
</script>
<style scoped>
.toggleMenu {
  display: block;
  width: 35px;
}
.toggleMenu span {
  width: 35px;
  height: 4px;
  display: block;
  background-color: black;
  margin: 6px 0;
}
</style>