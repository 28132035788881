<template>
  <form class="register-form" @submit.prevent="validateCode">
    <div class="container flex h-screen-calc items-center">
      <div class="flex w-full">
        <div class="w-full space-y-8 pt-20 px-4 md:px-0">
          <div class="w-full md:flex items-center">
            <div class="w-full md:w-6/12 space-y-4">
              <h2
                class="main-color text-6xl font-bold remove-space text-center"
              >
                Just one<br />more step
              </h2>
            </div>
            <div class="main-color text-6xl font-bold remove-space text-center">
              <h3 class="main-color font-semibold text-2xl">
                Click on the link that you received.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { object, string } from "yup";
// import CodeInput from "vue-verification-code-input";

export default {
  name: "RegisterStep2",
  components: {
    // CodeInput,
  },
  data() {
    return {
      firstName: "",
      values: {
        code: "",
      },
      errors: {
        code: "",
      },
      loginFormSchema: object().shape({
        code: string()
          .min(6, this.$t("ErrorMinLength", { c: 6 }))
          .max(6, this.$t("ErrorMaxLength", { c: 6 }))
          .required(this.$t("ErrorIsRequired")),
      }),
    };
  },
  async created() {
    const data = await this.$getUserData();
    this.firstName = data.data.user.firstName;
  },
  methods: {
    onComplete(v) {
      this.values.code = v;
      console.log("onComplete ", v);
    },
    validateCode() {
      var self = this;

      this.loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async (e) => {
          const response = await self.$validateCode(e);
          if (response.data.success === true) {
            // go to step 2
            self.$logUserIn(response.data.token);
            self.$router.push("/");
          } else {
            if (response.data.success === "userIsActive") {
              self.$router.push("/");
            }
            self.$toast.error(self.$t(response.data.error), {
              position: "bottom-left",
            });
          }

          // self.$toast.error("your message");
        })
        .catch((err) => {
          console.log(err);
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    validate(field) {
      this.loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>

<style scoped>
</style>>
