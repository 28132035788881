<template>
  <div class="page-inner">
    <MainMenu />
    <div class="main-page">
      <Account />
      <div class="w-full pt-10">
        <EditCampaignForm :key="$route.params.id" />
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "../common/MainMenu";
import Account from "../common/Account";

import EditCampaignForm from "../campaign/EditCampaignForm";
export default {
  name: "EditCampaign",
  components: { MainMenu, EditCampaignForm, Account },
  data() {
    return {
      id: "",
    };
  },
  mounted() {
    this.id = this.$route.params.id;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
