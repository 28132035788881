<template>
  <div class="wizard clearfix border-0 pb-5">
    <div class="steps clearfix">
      <ul role="tablist" class="justify-content-between">
        <li
          role="tab"
          :class="`first ${step >= 1 ? 'current' : ''}`"
          aria-disabled="false"
          aria-selected="true"
        >
          <a id="wizard1-t-0" aria-controls="wizard1-p-0"
            ><span :class="` audible ${step >= 1 ? 'current-info' : ''}`">
            </span
            ><span class="number rounded-circle">1</span>
          </a>
        </li>
        <li
          role="tab"
          :class="`first ${step >= 2 ? 'current' : ''}`"
          aria-disabled="false"
          aria-selected="true"
        >
          <a id="wizard1-t-0" aria-controls="wizard1-p-0"
            ><span :class="` audible ${step >= 2 ? 'current-info' : ''}`">
            </span
            ><span class="number rounded-circle">2</span>
          </a>
        </li>
        <li
          role="tab"
          :class="`first ${step >= 3 ? 'current' : ''}`"
          aria-disabled="false"
          aria-selected="true"
        >
          <a id="wizard1-t-0" aria-controls="wizard1-p-0"
            ><span :class="` audible ${step >= 3 ? 'current-info' : ''}`">
            </span
            ><span class="number rounded-circle">3</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["step"],
};
</script>

<style>
</style>
