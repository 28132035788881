<template>
  <el-form-item
    :label="currentField.label"
    :label-width="currentField.labelWidth + 'px'"
    :required="this.currentField.isRequired"
  >
    <el-checkbox-group v-model="checkList">
      <el-checkbox
        v-for="item in currentField.options"
        :key="item.optionValue"
        :label="item.optionValue"
        :disabled="item.disabled"
      >
      </el-checkbox>
    </el-checkbox-group>
  </el-form-item>
</template>

<script>
export default {
  name: "Checkbox",
  props: ["currentField"],
  data() {
    return {
      checkList: [0],
    };
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      // let dataUrl = this.currentField.dataUrl;
      // let valueField = this.currentField.valueField;
      // let labelField = this.currentField.labelField;
      // let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      // promise.then((data) => {
      //   this.currentField.options = data;
      // });
    }
  },
};
</script>

<style>
.el-checkbox-group {
  font-size: 14px;
}
</style>
