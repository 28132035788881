<template>
  <form class="w-full space-y-3" @submit.prevent="loginUser">
    <div class="w-full md:flex items-center space-x-4">
      <h3 class="main-color font-semibold text-4xl p-0 m-0">
        Design your form
      </h3>
      <router-link
        class="tab active-tabs"
        v-if="isSaved == 1"
        :to="`/campaign/${$route.params.id}`"
        >Edit campaign</router-link
      >
      <router-link
        class="tab"
        v-if="isSaved == 1"
        :to="`/campaign/formbuilder/${$route.params.id}`"
        >Edit flow</router-link
      >
    </div>

    <div class="md:flex w-full justify-between">
      <div class="w-full space-y-4">
        <div
          class="
            w-full
            md:flex
            space-y-12
            xl:space-y-0 xl:space-x-12
            sm-flex-item
          "
        >
          <div class="w-full md:w-8/12 bg-white rounded-2xl px-10 py-3 min-w-p">
            <form-input
              :label="$t('EnterCampaignName')"
              :info="$t('SomeInfo1')"
              v-model="values.campaignName"
              type="text"
              beforeClass="input-name"
              @validate="validate('campaignName')"
              name="code"
              :error="errors.campaignName"
            ></form-input>
            <div class="form-group">
              <p class="mb-2 flex items-center">
                Show Campaign name
                <span class="has-tooltip">
                  <p class="tooltip">
                    <i class="triangle"></i>
                    {{ $t("AvatarInfo") }}
                  </p>

                  <img src="../../assets/images/i.svg" />
                </span>
              </p>

              <div class="">
                <label for="toggleB" class="flex items-center cursor-pointer">
                  <!-- toggle -->
                  <div class="relative">
                    <!-- input -->
                    <input
                      type="checkbox"
                      id="toggleB"
                      name="custom-switch-checkbox"
                      v-model="values.showCampaignName"
                    />
                    <!-- line -->
                    <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
                    <!-- dot -->
                    <div
                      class="
                        dot
                        absolute
                        left-1
                        top-1
                        bg-white
                        w-6
                        h-6
                        rounded-full
                        transition
                      "
                    ></div>
                  </div>
                  <!-- label -->
                  <div class="ml-3 text-gray-700 font-medium">
                    {{
                      values.showCampaignName === true ||
                      values.showCampaignName === "live"
                        ? "Active"
                        : "Hidden"
                    }}
                  </div>
                </label>
              </div>
            </div>

            <form-input
              :label="$t('DomainLabel')"
              v-model="values.domain"
              :info="$t('DomainInfo')"
              type="text"
              placeholder="http://www.yoursite.ro"
              beforeClass="input-domain"
              @validate="validate('domain')"
              name="code"
              :error="errors.domain"
            ></form-input>
            <div class="form-group">
              <p class="mb-2 flex items-center">
                Avatar Visible

                <span class="has-tooltip">
                  <p class="tooltip">
                    <i class="triangle"></i>
                    {{ $t("AvatarInfo") }}
                  </p>

                  <img src="../../assets/images/i.svg" />
                </span>
              </p>

              <div class="">
                <label for="toggleB" class="flex items-center cursor-pointer">
                  <!-- toggle -->
                  <div class="relative">
                    <!-- input -->
                    <input
                      type="checkbox"
                      id="toggleB"
                      name="custom-switch-checkbox"
                      v-model="values.avatarVisible"
                    />
                    <!-- line -->
                    <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
                    <!-- dot -->
                    <div
                      class="
                        dot
                        absolute
                        left-1
                        top-1
                        bg-white
                        w-6
                        h-6
                        rounded-full
                        transition
                      "
                    ></div>
                  </div>
                  <!-- label -->
                  <div class="ml-3 text-gray-700 font-medium">
                    {{ values.avatarVisible === true ? "Active" : "Hidden" }}
                  </div>
                </label>
              </div>
            </div>

            <form-input
              label="Avatar name"
              v-model="values.avatarName"
              type="text"
              :info="$t('SomeInfo1')"
              @validate="validate('avatarName')"
              name="code"
              beforeClass="input-avatar"
              :error="errors.avatarName"
            ></form-input>

            <div class="form-group">
              <p class="mb-2">Avatar</p>
              <div class="custom-file mb-5">
                <input
                  class="hidden"
                  id="customFile"
                  ref="file"
                  v-on:change="handleFileUpload()"
                  type="file"
                />
                <label
                  class="
                    custom-file-label
                    border border-blue-900
                    cursor-pointer
                    px-4
                    py-2
                    rounded-xl
                  "
                  for="customFile"
                  >Choose file</label
                >
              </div>
              <img
                :src="avatar"
                style="max-width: 200px; max-height: 200px"
                v-if="avatar !== ''"
              />
              <p
                class="invalid-feedback"
                :style="` ${!!errors.theme ? 'display:block' : ''}`"
              >
                {{ errors.theme }}
              </p>
            </div>
            <div class="form-group">
              <p class="mb-2">Theme</p>
              <v-swatches
                v-model="values.theme"
                show-fallback
                fallback-input-type="color"
                popover-x="right"
              ></v-swatches>
              <p
                class="invalid-feedback"
                :style="` ${!!errors.theme ? 'display:block' : ''}`"
              >
                {{ errors.theme }}
              </p>
            </div>
            <div class="flex space-x-8">
              <div class="w-6/12">
                <div class="form-group">
                  <p class="mb-2 flex items-center">
                    Start Date

                    <span class="has-tooltip">
                      <p class="tooltip">
                        <i class="triangle"></i>
                        {{ $t("AvatarInfo") }}
                      </p>

                      <img src="../../assets/images/i.svg" />
                    </span>
                  </p>
                  <datetime
                    type="datetime"
                    v-model="values.startDate"
                  ></datetime>

                  <p
                    class="invalid-feedback"
                    :style="` ${!!errors.startDate ? 'display:block' : ''}`"
                  >
                    {{ errors.startDate }}
                  </p>
                </div>
              </div>
              <div class="w-6/12">
                <div class="form-group">
                  <p class="mb-2 flex items-center">
                    End Date
                    <span class="has-tooltip">
                      <p class="tooltip">
                        <i class="triangle"></i>
                        {{ $t("AvatarInfo") }}
                      </p>

                      <img src="../../assets/images/i.svg" />
                    </span>
                  </p>
                  <datetime type="datetime" v-model="values.endDate"></datetime>
                  <p
                    class="invalid-feedback"
                    :style="` ${!!errors.endDate ? 'display:block' : ''}`"
                  >
                    {{ errors.endDate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <p class="mb-2">Campaign Status</p>
              <div class="">
                <label for="toggleC" class="flex items-center cursor-pointer">
                  <!-- toggle -->
                  <div class="relative">
                    <!-- input -->
                    <input
                      type="checkbox"
                      id="toggleC"
                      name="custom-switch-checkbox"
                      v-model="values.status"
                    />
                    <!-- line -->
                    <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
                    <!-- dot -->
                    <div
                      class="
                        dot
                        absolute
                        left-1
                        top-1
                        bg-white
                        w-6
                        h-6
                        rounded-full
                        transition
                      "
                    ></div>
                  </div>
                  <!-- label -->
                  <div class="ml-3 text-gray-700 font-medium">
                    {{ values.status === true ? "Active" : "Paused" }}
                  </div>
                </label>
              </div>
            </div>
            <div class="form-group" style="display: none">
              <p class="mb-2">Type</p>
              <select
                name="country"
                v-model="values.type"
                class="form-control-select"
              >
                <option value="">Select type</option>
                <option value="type1">Type 1</option>
                <option value="type2">Type 2</option>
                <option value="type3">Type 3</option>
                <option value="type5">Type 4</option>
                <option value="type6">Type 5</option>
              </select>
              <p
                class="invalid-feedback"
                :style="` ${!!errors.type ? 'display:block' : ''}`"
              >
                {{ errors.type }}
              </p>
            </div>
            <div class="w-full md:flex space-x-12">
              <div
                class="
                  w-full
                  md:w-full
                  rounded-2xl
                  flex
                  items-center
                  justify-center
                "
              >
                <button class="main-btn">Save</button>
              </div>
            </div>
          </div>
          <div class="w-full md:w-4/12 bg-white rounded-2xl px-10 py-3 min-w-p">
            <h3 class="main-color font-semibold text-2xl pt-11">
              Design your form
            </h3>
            <p class="py-12">
              Here’s something you can do to<br />
              increse this form traffic.
            </p>
            <ul class="list-faq">
              <li>
                <span class="main-color">1</span>
                <p>Reduce the number of questions</p>
              </li>

              <li>
                <span class="main-color">2</span>
                <p>Ask for little personal data</p>
              </li>

              <li>
                <span class="main-color">3</span>
                <p>Change the button position</p>
              </li>
            </ul>
            <div class="py-12">
              <a
                v-if="isSaved == 1"
                :href="`/campaign/formbuilder/${$route.params.id}`"
                class="main-btn-outline is-full"
                >Edit flow</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { object, string } from "yup";
import FormInput from "../common/form/FormInput";
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
import { DateTime as LuxonDateTime } from "luxon";

import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
  name: "Login",
  components: {
    "form-input": FormInput,
    Datetime,
    VSwatches,
  },
  data() {
    return {
      file: null,
      avatar: "",
      isSaved: 0,

      values: {
        campaignName: "",
        domain: "",
        avatarVisible: "",
        avatarName: "",
        status: "",
        showCampaignName: "",
        startDate: LuxonDateTime.local().toISO(),
        endDate: LuxonDateTime.local().toISO(),
        theme: "",
        type: "",
      },
      errors: {
        campaignName: "",
        domain: "",
        status: "",
        startDate: "",
        avatarVisible: "",
        avatarName: "",
        endDate: "",
        theme: "",
        type: "",
      },
      loginFormSchema: object().shape({
        type: string()
          .min(1, this.$t("ErrorMinLength", { c: 6 }))
          .required(this.$t("ErrorIsRequired")),
        startDate: string().nullable().required(this.$t("ErrorIsRequired")),
        endDate: string().nullable().required(this.$t("ErrorIsRequired")),

        avatarName: string().notRequired(),

        campaignName: string()
          .min(1, this.$t("ErrorMinLength", { c: 6 }))
          .max(100, this.$t("ErrorMaxLength", { c: 100 }))
          .required(this.$t("ErrorIsRequired")),
        domain: string()
          .url(this.$t("ErrorUrlIsNotValid"))
          .required(this.$t("ErrorIsRequired")),
      }),
    };
  },
  async created() {
    if (this.$route.params.id) {
      const response = await this.$getUserCampaign(this.$route.params.id);
      console.log("response", response);
      if (response.success === false || !response.campaign) {
        this.$toast.error(this.$t("ErrorLoadingCampaign"), {
          position: "bottom-left",
        });
      } else {
        this.values.campaignName =
          response.campaign.name != "-" ? response.campaign.name : "";
        this.values.domain =
          response.campaign.domain != "-" ? response.campaign.domain : "";

        if (response.campaign.domain != "-") {
          this.isSaved = 1;
        }

        this.values.showCampaignName = response.campaign.showCampaignName;
        this.values.theme = response.campaign.theme;
        this.avatar = response.campaign.avatar;
        this.values.avatarName = response.campaign.avatarName;
        this.values.avatarVisible =
          response.campaign.avatarVisible !== "live" ? false : true;
        this.values.startDate = response.campaign.startDate;
        this.values.endDate = response.campaign.endDate;
        this.values.status =
          response.campaign.status === "paused" ? false : true;
        this.values.type = response.campaign.type;
      }
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.avatar = URL.createObjectURL(this.$refs.file.files[0]);
    },
    async submit(e) {
      try {
        const response = await this.$updateCampaign(
          e,
          this.$route.params.id,
          this.file
        );
        if (response && response && response.success === true) {
          console.log("this.$toast", this.$toast);
          this.$toast.success(this.$t("CampaignUpdated"), {
            position: "bottom-left",
          });
        } else {
          this.$toast.error(this.$t(response.data.error), {
            position: "bottom-left",
          });
        }
      } catch (error) {
        console.log("submit", error);
      }
    },
    loginUser() {
      var self = this;
      this.loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async (e) => {
          self.submit(e);
          if (self.isSaved == 0)
            self.$router.push(`/campaign/formbuilder/${self.$route.params.id}`);
          self.isSaved = 1;

          // self.$toast.error("your message");
        })
        .catch((err) => {
          console.log(err);
          if (err.inner)
            err.inner.forEach((error) => {
              console.log(error);
              this.errors = { ...this.errors, [error.path]: error.message };
            });
        });
    },
    validate(field) {
      this.loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>

<style>
</style>
