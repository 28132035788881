<template>
  <div class="hidden md:block w-full bg-transparent py-10 justify-end">
    <div class="w-2/12 float-right relative user-menu">
      <div @click="toggle" class="display-dropdown">
        <img src="../../assets/images/my-account.svg" />
        <p>My account</p>
        <img src="../../assets/images/arrow.svg" />
      </div>
      <div :class="`items ${toggleInfo ? 'active' : ''} `">
        <ul>
          <li><a href="#" @click="logout">Logout </a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Account",

  props: {
    msg: String,
  },
  methods: {
    toggle() {
      this.toggleInfo = !this.toggleInfo;
    },
    logout() {
      this.$logUserOut();
      this.$router.push("/login");
    },
  },
  data() {
    return {
      toggleInfo: false,
    };
  },
};
</script>