<template>
  <div class="page-inner">
    <MainMenu />

    <div class="main-page">
      <Account />
      <div class="w-full pt-10 space-y-3">
        <div class="w-full flex items-center space-x-4">
          <h3 class="main-color font-semibold text-4xl">Get ready, set, GO!</h3>
        </div>
        <div class="md:flex w-full justify-between">
          <IntegrationCampaignForm :key="$route.params.id" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Account from "../common/Account";
import MainMenu from "../common/MainMenu";
import IntegrationCampaignForm from "../campaign/IntegrationCampaignFormData";
export default {
  name: "CampaignIntegrationPage",
  components: { MainMenu, IntegrationCampaignForm, Account },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
