<template>
  <div>
    <Header textLink="Login" link="/login" />
    <Step1 />
    <Footer />
  </div>
</template>

<script>
import Header from "../common/auth/header";
import Step1 from "./register/Step1";
import Footer from "../common/auth/footer";

export default {
  name: "Register",
  components: {
    Header,
    Step1,
    Footer,
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    gotoStep(i) {
      this.step = i;
    },
  },
};
</script>

<style scoped>
.wizard {
  border: 0;
}
.wizard > .steps a .number,
.wizard > .steps a:hover .number,
.wizard > .steps a:active .number {
  width: 45px;
  height: 45px;
  font-size: 18px;
  line-height: 45px;
}
</style>>
