<template>
  <div class="form-group">
    <label v-if="label" class="form-label flex items-center" for="email"
      >{{ label }}
      <span class="has-tooltip" v-if="info">
        <p class="tooltip">
          <i class="triangle"></i>
          {{ info }}
        </p>

        <img src="../../../assets/images/i.svg" />
      </span>
    </label>
    <input
      :id="name"
      :placeholder="placeholder"
      :name="name"
      :type="type"
      :value="value"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
      :class="[
        'form-control',
        beforeClass || '',
        !!error && 'is-invalid state-invalid',
      ]"
      class="form-control"
      @blur="$emit('validate')"
      @keypress="$emit('validate')"
    />
    <p class="invalid-feedback" v-if="!!error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "form-input",
  props: {
    type: { required: true },
    beforeClass: { required: false },
    info: { required: false },
    placeholder: { required: false },
    label: { required: false },
    name: { required: true },
    value: { required: true },
    error: { required: true },
    maxlength: { required: false, default: 255 },
  },
};
</script>