<template>
  <div>
    <Header textLink="Login" link="/login" />
    <Step2 />
    <Footer />
  </div>
</template>

<script>
import Header from "../common/auth/header";
import Footer from "../common/auth/footer";

import Step2 from "./register/Step2";

export default {
  name: "RegisterValidate",
  components: {
    Header,
    Step2,
    Footer,
  },
  data() {
    return {
      step: 2,
      user: false,
    };
  },
  async mounted() {
    const user = await this.$getUser();
    if (!user.status || user.status === "new") {
      this.user = user;
    } else {
      self.$router.push("/register/onboarding");
    }
  },
  methods: {
    gotoStep(i) {
      this.step = i;
    },
  },
};
</script>

<style scoped>
.wizard {
  border: 0;
}
.wizard > .steps a .number,
.wizard > .steps a:hover .number,
.wizard > .steps a:active .number {
  width: 45px;
  height: 45px;
  font-size: 18px;
  line-height: 45px;
}
</style>>
