<template>
  <div class="page-inner">
    <MainMenu />
    <div class="main-page">
      <Account />
      <div class="w-full pt-10 space-y-3">
        <div class="xl:flex w-full justify-between">
          <div class="xl:flex w-full justify-between items-center">
            <h3 class="main-color font-semibold text-2xl p-0 m-0">
              Use the templates below to create a new campaign
            </h3>
          </div>

          <div
            class="
              w-full
              md:w-3/12
              block
              xl:flex
              items-center
              xl:space-x-4
              justify-end
            "
          >
            <p
              class="w-full block xl:w-min text-center xl:text-lef pb-5 md:pb-0"
            >
              or
            </p>
            <router-link to="/create-campaign" class="main-btn is-big block"
              >Create an empty campaign</router-link
            >
          </div>
        </div>
      </div>
      <ListBuildForms />
    </div>
  </div>
</template>

<script>
import MainMenu from "../common/MainMenu";
import Account from "../common/Account";
import ListBuildForms from "../common/ListBuildForms";
export default {
  name: "Dashboard",
  components: { MainMenu, Account, ListBuildForms },
  mounted() {
    this.getCampaigns();
  },
  methods: {
    async getCampaigns() {
      const response = await this.$getUserCampaigns();
      if (response.success === true) {
        this.$store.dispatch("campaigns/setItems", response.campaigns);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
