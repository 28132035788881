<template>
  <div class="meta">
    <el-row>
      <draggable
        v-model="items"
        class="dragArea"
        :group="{ name: 'formbuilder', pull: false, put: true }"
        :sort="true"
        ghost-class="sortable__ghost"
      >
        <!-- The form elements starts (on the right) -->
        <!-- <div> -->
        <div v-if="sectionField.length == 0">
          <h4 class="main-color font-semibold text-xl pt-14">
            {{ $t("Dragdropafieldtypefromtherighthere") }}
          </h4>
        </div>
        <el-col
          v-for="(field, index) in sectionField"
          :key="index"
          v-bind="field"
          :span="field.span"
          class="form__group"
          :class="{ 'is--active': field === activeField }"
        >
          <span class="form__selectedlabel">{{ field.fieldType }}</span>

          <div @click="editElementProperties(field)">
            <!-- <label class="form__label" v-model="form.label" v-show="form.hasOwnProperty('label')">{{ form.label }}</label> -->
            <component
              :is="field.fieldType"
              :currentField="field"
              class="form__field"
            >
            </component>
          </div>

          <!-- Actions list -->
          <div class="form__actiongroup">
            <el-button
              circle
              size="mini"
              icon="el-icon-rank"
              class="form__actionitem--move"
            ></el-button>

            <el-button-group class="form__actionlist">
              <el-button
                size="mini"
                icon="el-icon-plus"
                @click="cloneElement(index, field, eachFormIndex)"
                v-show="!field.isUnique"
              ></el-button>
              <el-button
                size="mini"
                icon="el-icon-delete"
                @click="deleteElement(index, eachFormIndex)"
              ></el-button>
            </el-button-group>
          </div>
        </el-col>
        <!-- </div> -->
      </draggable>
    </el-row>
  </div>
</template>
<script>
import { FormBuilder } from "./form_elements/formbuilder";
import { mapGetters } from "vuex";

export default {
  name: "DraggableList",
  props: ["fields", "index"],
  components: FormBuilder.$options.components,
  computed: {
    ...mapGetters("formbuilder", {
      activeField: "getActiveField",
      activeTabForFields: "getActiveTabForFields",
    }),

    sectionField() {
      return this.$store.getters["formbuilder/getSectionField"](this.index);
      // the value 2 is the argument I pass to my getter function
    },
  },
  // store: ["forms", "activeField", "activeTabForFields"],
  data() {
    return {
      items: [],
      eachFormIndex: 0,
    };
  },
  created() {
    this.items = this.$store.getters["formbuilder/getSectionField"](this.index);
    this.eachFormIndex = this.index;
  },
  watch: {
    items(newVal) {
      this.$store.dispatch("formbuilder/addFormFields", {
        fields: newVal,
        index: this.eachFormIndex,
      });
    },
  },
  methods: {
    deleteElement(index, formIndex) {
      FormBuilder.deleteElement(index, formIndex);
      this.items = this.$store.getters["formbuilder/getSectionField"](
        this.index
      );
    },
    cloneElement(index, field, formIndex) {
      FormBuilder.cloneElement(index, field, formIndex);
    },
    editElementProperties(field) {
      FormBuilder.editElementProperties(field);
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.empty-section {
  text-align: center;
  font-size: 40px;
  background: linear-gradient(to bottom, #fff, #409eff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.dragArea {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 10px;
  height: calc(30vh);
  z-index: 2;
}

.el-main-left {
  height: calc(90vh);
}

.form__selectedlabel {
  display: none;
  background: #ecf5ff;
  padding: 3px 5px;
  color: black;
  font-size: 10px;
  position: absolute;
  top: -17px;
  right: 15px;
}

.form__actionitem--move {
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;

  &:active,
  &:focus,
  &:hover {
    border-color: #409eff;
    background: #ecf5ff;
  }
}

.form__actionlist {
  position: absolute;
  margin-top: 10px;
  visibility: hidden;
  z-index: 3;
  right: 0;
  border-radius: 2px;
}

.form__group {
  margin-bottom: 25px;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border-color: #409eff;

    .form__actionitem--move {
      visibility: visible;
    }
  }

  &.is--active {
    border-color: #409eff;
    background: #ecf5ff;

    .form__actionlist {
      visibility: visible;
    }
    .form__selectedlabel {
      display: inline-block;
    }
  }
}

.section-block {
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.section-block .source {
  padding: 10px;
}

.section-block .meta {
  background-color: #fafafa;
  border-top: 1px solid #eaeefb;
}
.dragArea {
  padding: 10px 20px;
}
</style>
