const state = () => ({
  campaigns: [],
  currentCampaign: ""
})

// getters
const getters = {
  listCampaigns: (state) => {
    return state.campaigns
  },

  getCurrentCampaign: (state) => {
    return state.currentCampaign
  },

  getTotalCampaigns: (state, getters) => {
    return getters.campaigns.length;
  }
}

// actions
const actions = {
  setItems({ commit }, items) {
    commit('setCampaigns', { items: items })

  },

  setCurrentCampaign({ commit }, uuid) {
    commit('setCurrentCampaign', { uuid: uuid })

  },


}

// mutations
const mutations = {
  setCampaigns(state, { items }) {
    state.campaigns = items;
  },
  setCurrentCampaign(state, { uuid }) {
    state.currentCampaign = uuid
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}