<template>
  <div class="page-inner">
    <MainMenu />

    <div class="main-page">
      <Account />
      <div class="w-full pt-10 space-y-3">
        <FormBuilder :key="$route.params.id" />
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "../common/MainMenu";
import FormBuilder from "../campaign/FormBuilder";
import Account from "../common/Account";

export default {
  name: "EditFlow",
  components: { MainMenu, FormBuilder, Account },
  data() {
    return {
      id: "",
    };
  },
  mounted() {
    this.id = this.$route.params.id;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
