<template>
  <div class="source relative">
    <div class="icons">
      <a @click="deleteSection(eachFormIndex, 'Section')">
        <img src="../../../assets/images/delete-icon.svg" />
      </a>
    </div>

    <form-input
      :placeholder="'Step title'"
      v-model="sectionTitle"
      type="text"
      :error="''"
      beforeClass="section-title"
      name="sectionTitle"
    ></form-input>
  </div>
</template>
<script>
import FormInput from "../../common/form/FormInput";

export default {
  name: "TitleSection",
  components: { "form-input": FormInput },
  props: ["title", "index"],
  data() {
    return {
      eachFormIndex: 0,
      sectionTitle: "",
    };
  },
  created() {
    this.eachFormIndex = this.index;
    this.sectionTitle = this.title;
  },
  watch: {
    sectionTitle(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.$store.dispatch("formbuilder/setFormTitle", {
        title: newVal,
        index: this.index,
      });
    },
  },
  methods: {
    deleteSection(formIndex, formTitle) {
      this.$confirm(
        `Are you sure to delete the section ${formTitle}?`,
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.$store.dispatch("formbuilder/deleteItem", formIndex);
        this.getForms();
      });
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.empty-section {
  text-align: center;
  font-size: 40px;
  background: linear-gradient(to bottom, #fff, #409eff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.dragArea {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 10px;
  height: calc(30vh);
  z-index: 2;
}

.el-main-left {
  height: calc(90vh);
}

.form__selectedlabel {
  display: none;
  background: #ecf5ff;
  padding: 3px 5px;
  color: black;
  font-size: 10px;
  position: absolute;
  top: -17px;
  right: 15px;
}

.form__actionitem--move {
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;

  &:active,
  &:focus,
  &:hover {
    border-color: #409eff;
    background: #ecf5ff;
  }
}

.form__actionlist {
  position: absolute;
  margin-top: 10px;
  visibility: hidden;
  z-index: 3;
  right: 0;
  border-radius: 2px;
}

.form__group {
  margin-bottom: 25px;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border-color: #409eff;

    .form__actionitem--move {
      visibility: visible;
    }
  }

  &.is--active {
    border-color: #409eff;
    background: #ecf5ff;

    .form__actionlist {
      visibility: visible;
    }
    .form__selectedlabel {
      display: inline-block;
    }
  }
}

.section-block {
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.section-block .source {
  padding: 10px;
}

.section-block .meta {
  background-color: #fafafa;
  border-top: 1px solid #eaeefb;
}
</style>
