<template>
  <div class="w-full pb-12">
    <div
      class="w-full md:flex items-center space-y-4 md:space-y-0 md:space-x-4"
    >
      <h3 class="main-color font-semibold text-4xl m-0" style="line-height: 2">
        Design your form
      </h3>
      <button type="button" class="main-btn" @click="addSection">
        {{ $t("Addsteps") }}
      </button>
    </div>
    <div class="w-full flex items-center space-x-4">
      <h4 class="main-color font-semibold text-xl">
        {{ $t("Pleaseaddsections") }}
      </h4>
    </div>

    <div class="w-full justify-between">
      <form class="register-form" method="post" @submit.prevent="save">
        <div class="md:flex w-full flex-wrap">
          <el-main class="el-main-left p-0 w-full xl:w-3/4">
            <div class="wrapper--forms">
              <el-form method="post">
                <el-row v-if="formsItems && formsItems.length === 0">
                  <div class="empty-section">
                    <h3 class="main-color">Please add steps</h3>
                  </div>
                </el-row>
                <el-row v-if="formsItems && formsItems.length !== 0">
                  <template v-for="(eachFormObj, eachFormIndex) of formsItems">
                    <div :key="`div-${eachFormIndex}`" class="section-block">
                      <TitleSection
                        :title="eachFormObj.title"
                        :index="eachFormIndex"
                      />
                      <draggableList
                        :fields.sync="eachFormObj.fields"
                        :index="eachFormIndex"
                      />
                    </div>
                  </template>
                </el-row>
              </el-form>
              <a type="button" class="main-btn-outline" @click="addSection">
                {{ $t("Addsteps") }}
              </a>
              <button
                type="submit"
                class="main-btn float-right cursor-pointer"
                @click="save"
                style="
                  margin-bottom: 30px;
                  width: 90%;
                  margin: 0 auto;
                  max-width: 300px;
                  display: block;
                "
              >
                Save Form
              </button>
            </div>
          </el-main>
          <div class="position-relative pt-12 xl:pt-0 w-full xl:w-1/4">
            <el-aside class="wrapper--sidebar" width="100%; min-width:300px">
              <el-tabs type="border-card" v-model="activeTabForFields">
                <el-tab-pane name="elements" label="Elements">
                  <elements />
                </el-tab-pane>
                <el-tab-pane name="properties" label="Properties">
                  <properties
                    :key="`${activeField.id}`"
                    v-show="Object.keys(activeField).length > 0"
                  ></properties>
                </el-tab-pane>
              </el-tabs>

              <!--{{ $store.activeField }}-->
            </el-aside>
          </div>
        </div>
      </form>

      <div class="el-main-left p-0 w-full xl:w-3/4 mt-5">
        <div
          class="
            bg-yellow-100
            border border-yellow-400
            text-yellow-700
            px-4
            py-3
            rounded
            relative
          "
          role="alert"
        >
          <span class="block sm:inline"
            >Don't forget to add a thank you message.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormBuilder } from "./form-builder/form_elements/formbuilder";
import draggableList from "./form-builder/draggableList";
import TitleSection from "./form-builder/TitleSection";

import { mapGetters } from "vuex";

export default {
  name: "FormBuilderWizzard",
  // store: ["forms", "activeField", "activeTabForFields"],
  data() {
    return {
      isSubmiting: 0,
      // formsItems: [],
      sortElementOptions: FormBuilder.$data.sortElementOptions,
    };
  },
  computed: {
    ...mapGetters("formbuilder", {
      activeField: "getActiveField",
      formsItems: "getForms",
      //  formsItems: "getForms",
    }),
    activeTabForFields: {
      get() {
        return this.$store.getters["formbuilder/getActiveTabForFields"];
      },
      set(val) {
        this.$store.dispatch("formbuilder/setActiveTabForFields", {
          val,
        });
      },
      // the value 2 is the argument I pass to my getter function
    },
    // formsItems: {
    //   get() {
    //     return this.$store.getters["formbuilder/getForms"];
    //   },
    //   set(value) {
    //     console.log(value);
    //     // this.$store.commit("setNotification", value);
    //   },
    // },
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.getCampaigns();
    next();
  },

  created() {
    this.getCampaigns();
  },

  components: {
    ...FormBuilder.$options.components,
    draggableList,
    TitleSection,
  },
  methods: {
    async getCampaigns() {
      const response = await this.$getCampaignFlow(this.$route.params.id);
      if (response.data.flow) {
        this.$store.dispatch("formbuilder/initiate", response.data.flow);
      } else {
        this.$store.dispatch("formbuilder/initiate", []);
      }
    },
    async save() {
      if (this.isSubmiting == 1) return false;
      this.isSubmiting = 1;
      try {
        const data = JSON.stringify(
          this.$store.getters["formbuilder/getForms"]
        );

        const response = await this.$saveFlow(data, this.$route.params.id);
        if (response && response.data && response.data.success === true) {
          this.$toast.success(this.$t("FlowSaved"), {
            position: "bottom-left",
          });
        } else {
          this.$toast.error(this.$t(response.data.error), {
            position: "bottom-left",
          });
        }
        this.isSubmiting = 0;
        return false;
      } catch (error) {
        this.isSubmiting = 0;
        console.log("submit", error);
        return false;
      }
    },
    updateFormBuilder(toUpdate) {
      this.$store.dispatch("formbuilder/setFormData", {
        toUpdate,
      });
    },
    addSection() {
      const formObj = {
        title: "",
        fields: [],
      };
      this.$store.dispatch("formbuilder/setItems", formObj);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.empty-section {
  text-align: center;
  font-size: 40px;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px 20px;
  border: 1px solid #8263f2;
  margin-right: 0px;
}

.dragArea {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 10px;
  height: calc(30vh);
  z-index: 2;
}

.el-main-left {
}

.form__selectedlabel {
  display: none;
  background: #ecf5ff;
  padding: 3px 5px;
  color: black;
  font-size: 10px;
  position: absolute;
  top: -17px;
  right: 15px;
}

.form__actionitem--move {
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;

  &:active,
  &:focus,
  &:hover {
    border-color: #409eff;
    background: #ecf5ff;
  }
}

.form__actionlist {
  position: absolute;
  margin-top: 10px;
  visibility: hidden;
  z-index: 3;
  right: 0;
  border-radius: 2px;
}

.form__group {
  margin-bottom: 25px;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border-color: #409eff;

    .form__actionitem--move {
      visibility: visible;
    }
  }

  &.is--active {
    border-color: #409eff;
    background: #ecf5ff;

    .form__actionlist {
      visibility: visible;
    }
    .form__selectedlabel {
      display: inline-block;
    }
  }
}

.section-block {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  margin-top: 20px;
  background: #fff;
}

.section-block .source {
  padding: 10px;
}

.section-block .meta {
  border-top: 1px solid #eaeefb;
  overflow: hidden;
  border-radius: 20px;
  padding-bottom: 40px;
}
.section-block {
  border: 1px solid #2329d6;
  border-radius: 15px;
  margin-bottom: 50px;
}
.wrapper--sidebar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
</style>
