<template>
  <div>
    <Header textLink="Login" link="/login" />

    <Footer />
  </div>
</template>

<script>
import { object, string, ref } from "yup";
import Footer from "../common/auth/footer";

import Header from "../common/auth/header";

export default {
  name: "Login",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      values: {
        password: "",
        confirm_password: "",
        code: "",
      },
      errors: {
        password: "",
        confirm_password: "",
      },
      loginFormSchema: object().shape({
        password: string()
          .min(2, this.$t("ErrorMinLength", { c: 6 }))
          .required(this.$t("ErrorIsRequired")),
        confirm_password: string().oneOf(
          [ref("password"), null],
          this.$t("ErrorPasswordMatch")
        ),
      }),
    };
  },
  created() {
    this.values.code = this.$route.params.id;
    this.submit();
  },
  methods: {
    async submit() {
      try {
        const response = await this.$validateCode(this.$route.params.id);
        if (response && response.data && response.data.success === true) {
          this.$toast.success(this.$t("AccountActivated"), {
            position: "bottom-left",
          });
          this.$router.push("/dashboard");
        } else {
          this.$toast.error(this.$t(response.data.error), {
            position: "bottom-left",
          });
          this.$router.push("/login");
        }
      } catch (error) {
        console.log("submit", error);
      }
    },
    loginUser() {
      var self = this;
      this.loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async (e) => {
          self.submit(e);
          // self.$toast.error("your message");
        })
        .catch((err) => {
          if (err.inner)
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
        });
    },
    validate(field) {
      this.loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>

<style>
</style>
