<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import { mapGetters, mapState } from "vuex";

export default {
  name: "App",
  components: {},
  created() {
    if (localStorage.getItem("user") !== null) {
      this.getCampaigns();
    }
  },
  mounted() {
    if (localStorage.getItem("user") !== null) {
      this.getCampaigns();
    }
  },
  methods: {
    async getCampaigns() {
      const response = await this.$getUserCampaigns();
      if (response.success === true) {
        this.$store.dispatch("campaigns/setItems", response.campaigns);
      }
    },
  },
};
</script>
