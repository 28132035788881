<template>
  <div class="w-full xl:flex xl:space-x-5">
    <div class="w-full xl:w-1/2">
      <h3
        class="
          main-color
          font-semibold
          text-2xl
          border-b border-gray-200
          pb-2
          mb-3
        "
      >
        Embed in a web page
      </h3>
      <h4
        @click="togglePlacement"
        class="flex cursor-pointer w-full justify-between py-2"
      >
        PLACEMENT IN PAGE
        <span>
          <i v-if="!placement" class="fas fa-chevron-down"></i>
          <i v-else class="fas fa-chevron-up"></i>
        </span>
      </h4>
      <div
        :class="`${
          !placement ? 'hidden ' : 'visible'
        } border-b border-gray-200 pb-2 mb-3 space-y-4`"
      >
        <div
          @click="setIntegrationType('standard')"
          :class="`${
            integretionType == 'standard' ? 'active' : ''
          } flex bg-white px-2 py-5 items-center rounded-xl hover:bg-gray-200 cursor-pointer`"
        >
          <div class="w-2/12">
            <img
              src="/assets/images/placement-standard.svg"
              class="mx-auto block"
            />
          </div>
          <div :class="` w-10/12 pr-2`">
            <h4 class="text-sm font-semibold">Standard</h4>
            <p class="text-sm">
              Rhoncus vitae sapien mattis amet, convallis sollicitudin.
            </p>
          </div>
        </div>
        <div
          @click="setIntegrationType('fullpage')"
          :class="`${
            integretionType == 'fullpage' ? 'active' : ''
          } flex bg-white px-2 py-5 items-center rounded-xl hover:bg-gray-200 cursor-pointer`"
        >
          <div class="w-2/12">
            <img src="/assets/images/full-page.svg" class="mx-auto block" />
          </div>
          <div class="w-10/12 pr-2">
            <h4 class="text-sm font-semibold">Full page</h4>
            <p class="text-sm">
              Rhoncus vitae sapien mattis amet, convallis sollicitudin.
            </p>
          </div>
        </div>
        <!-- <div
          class="flex bg-white px-2 py-5 items-center rounded-xl hover:bg-gray-200 cursor-pointer"
        >
          <div class="w-2/12">
            <img src="/assets/images/slider.svg" class="mx-auto block" />
          </div>
          <div class="w-10/12 pr-2">
            <h4 class="text-sm font-semibold">Slider</h4>
            <p class="text-sm">
              Rhoncus vitae sapien mattis amet, convallis sollicitudin.
            </p>
          </div>
        </div> -->
      </div>

      <div
        :class="`${
          !codeView ? 'hidden ' : 'visible'
        } border-b border-gray-200 pb-2 mb-3`"
      ></div>
      <h4
        @click="toggleCode"
        class="flex cursor-pointer w-full justify-between py-2"
      >
        WEB PAGE CODE
        <span>
          <i v-if="!codeView" class="fas fa-chevron-down"></i>
          <i v-else class="fas fa-chevron-up"></i>
        </span>
      </h4>
      <div
        :class="`${
          !codeView ? 'hidden ' : 'visible'
        } border-b border-gray-200 pb-2 mb-3`"
      >
        <code
          v-if="integretionType == 'standard'"
          class="w-full relative bg-white rounded-2xl block text-sm px-3 py-10"
        >
          &lt;script&gt; var campaignId = "{{ this.$route.params.id }} (function
          () { "; var s1 = document.createElement("script"), s0 =
          document.getElementsByTagName("script")[0]; s1.async = true; s1.src =
          "https://api.siteextender.com/js/core.js"; s1.charset = "UTF-8";
          s1.setAttribute("crossorigin", "*"); s0.parentNode.insertBefore(s1,
          s0); })(); &lt;/script&gt; &lt;div&gt; id="wrapperanywebform">
          &lt;/div&gt;
          <div @click="copy" class="copy"></div>
        </code>
        <code
          v-if="integretionType == 'fullpage'"
          class="w-full relative bg-white rounded-2xl block text-sm px-3 py-10"
        >
          <br />
          <a
            class="text-blue underline"
            :href="`https://anywebform.fra1.digitaloceanspaces.com/${this.$route.params.id}/index.html`"
            >https://anywebform.fra1.digitaloceanspaces.com/{{
              this.$route.params.id
            }}/index.html</a
          >
          <br />

          <div @click="copy" class="copy"></div>
        </code>
      </div>
    </div>
    <div
      :class="`w-full xl:w-1/2 space-y-3 ${
        integretionType == 'standard' ? 'opacity-100' : 'opacity-0'
      } `"
    >
      <div class="display-box">
        <div
          @click="setTab('desktop')"
          :class="`tab-b ${tab === 'desktop' ? 'active' : ''}`"
        >
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5562 0.765625H2.39513C1.62462 0.765625 1 1.39025 1 2.16076V9.13641C1 9.90691 1.62462 10.5315 2.39513 10.5315H13.5562C14.3267 10.5315 14.9513 9.90691 14.9513 9.13641V2.16076C14.9513 1.39025 14.3267 0.765625 13.5562 0.765625Z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.18555 13.3218H10.7661"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.97559 10.5317V13.322"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Desktop
        </div>
        <div
          @click="setTab('mobile')"
          :class="`tab-b ${tab === 'mobile' ? 'active' : ''}`"
        >
          <svg
            width="12"
            height="16"
            viewBox="0 0 12 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.37078 1.16943H2.39513C1.62462 1.16943 1 1.79405 1 2.56456V13.7256C1 14.4961 1.62462 15.1207 2.39513 15.1207H9.37078C10.1413 15.1207 10.7659 14.4961 10.7659 13.7256V2.56456C10.7659 1.79405 10.1413 1.16943 9.37078 1.16943Z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.88281 12.3306H5.89063"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Mobile
        </div>
      </div>
      <div
        style="height: 700px; min-width: 320px"
        :class="`${
          tab === 'desktop' ? 'w-full' : 'w-6/12'
        }  bg-white rounded-lg p-3 space-y-3 relative ease-in-out relative`"
      >
        <div class="placeholder-1"></div>
        <div class="placeholder-2"></div>
        <div class="placeholder-3"></div>
        <div class="placeholder-4"></div>
        <div class="placeholder-5"></div>
        <div class="placeholder-3"></div>
        <div class="placeholder-4"></div>
        <div class="placeholder-5"></div>
        <div id="wrapperanywebform"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      campaignName: "",
      id: "",
      integretionType: "standard",
      tab: "desktop",
      codeView: true,
      placement: true,
    };
  },
  async created() {
    this.setCampaignId();
  },
  beforeRouteUpdate(to, from, next) {
    this.setCampaignId();
    // Call the API query method when the URL changes
    next();
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://api.siteextender.com/js/core2.js?v=20"
    );
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    setCampaignId() {
      console.log(this.$route.params.id);
      window.campaignId = this.$route.params.id;
    },
    setIntegrationType(type) {
      this.integretionType = type;
    },
    setTab(tab) {
      this.tab = tab;
    },
    togglePlacement() {
      this.placement = !this.placement;
    },
    toggleCode() {
      this.codeView = !this.codeView;
    },
    copy() {
      const el = document.createElement("textarea");
      let val = "<xxxx>";
      val =
        val +
        ` var campaignId = "${this.id}"; (function () { var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0]; s1.async = true; s1.src = "https://forms.siteextender.com/js/core.js"; s1.charset = "UTF-8"; s1.setAttribute("crossorigin", "*"); })(); `;
      val = val + `</xxxx>`;
      val = val + '<div id="wrapperanywebform"> </div>';
      val = val.replace("xxxx", "script");
      val = val.replace("xxxx", "script");

      if (this.integretionType == "fullpage") {
        el.value = `https://anywebform.fra1.digitaloceanspaces.com/${this.$route.params.id}/index.html`;
      } else el.value = val;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$toast.success("Script code was copied successful", {
        position: "bottom-left",
      });
    },
  },
};
</script>
