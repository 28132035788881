<template>
  <div class="page-inner">
    <MainMenu />
    <div class="main-page">
      <Account />
      <div class="w-full pt-10 space-y-3">
        <div class="md:flex w-full justify-between">
          <CampaignDataPage :key="$route.params.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "../common/MainMenu";
import Account from "../common/Account";
import CampaignDataPage from "../common/data/CampaignPage";
export default {
  created() {},
  name: "Dashboard",
  components: { MainMenu, CampaignDataPage, Account },
  data: () => ({}),
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
