<template>
  <div class="w-full">
    <div class="space-y-4">
      <h3 class="main-color font-semibold text-2xl">{{ name }}</h3>
      <h5>{{ type }}</h5>
    </div>
    <div class="w-full md:flex space-x-4 pt-8 items-top items-center">
      <div class="w-full md:w-4/12">
        <div class="flex space-x-8">
          <div class="w-6/12">
            <div class="form-group">
              <datetime
                type="datetime"
                placeholder="From Date"
                v-model="startDate"
              ></datetime>
            </div>
          </div>
          <div class="w-6/12">
            <div class="form-group">
              <datetime
                type="datetime"
                placeholder="To Date"
                v-model="endDate"
              ></datetime>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-8/12 xl:justify-items-end">
        <a @click="exportAll" class="main-btn float-right cursor-pointer"
          >Export all data</a
        >
      </div>
    </div>
    <div
      class="
        w-full
        xl:flex
        space-y-4
        xl:space-y-0 xl:space-x-4
        pt-20
        xl:pt-8
        items-top
      "
    >
      <div
        class="
          w-full
          xl:w-4/12
          space-y-6
          xl:h-96
          xl:overflow-y-scroll
          pr-4
          customscrollbar
        "
      >
        <div
          :key="index"
          v-for="(item, index) of data"
          class="
            bg-white
            md:hover:bg-gray-100
            cursor-pointer
            rounded-xl
            px-2
            py-6
            xl:flex
            items-center
            space-x-2
          "
          @click="setActive(item.uuid)"
        >
          <div>
            <span
              :class="`${active !== item.uuid ? 'bull opacity-0' : 'bull'}`"
            ></span>
            <span class="w-10 main-color font-bold">{{ index + 1 }}</span>
            <span class="text-sm"> {{ item.created | moment }}</span>
          </div>
          <div
            v-if="active === item.uuid"
            class="block xl:hidden w-full xl:w-8/12 bg-white rounded-xl"
          >
            <div class="px-6 py-3" :key="item._id" v-for="item of userInfo">
              <strong>{{ item.key }}</strong
              ><br />
              <span>{{ item.value }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden xl:block w-full xl:w-8/12 bg-white rounded-xl">
        <div class="px-6 py-3" :key="item._id" v-for="item of userInfo">
          <strong>{{ item.key }}</strong
          ><br />
          <span>{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import vPagination from "vue-plain-pagination";
const types = {
  type1: "Custom",
  custom: "Custom",
  contact: "Contact Form",
  quiz: "Quiz",
  newsletter: "Newsletter Subscription",
  eventRegistration: "Event Registration",
  appoiment: "Appoiment",
};
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
import { DateTime as LuxonDateTime } from "luxon";

export default {
  created() {},
  name: "CampaignDataPage",
  components: { Datetime },
  data: () => ({
    loaded: false,
    header: null,
    active: "",
    name: "",
    type: "",
    selectedItem: "0",
    currentPage: 1,
    userInfo: [],
    data: null,
    total: 1,
    startDate: LuxonDateTime.local().startOf("day").toISO(),
    endDate: LuxonDateTime.local().endOf("day").toISO(),
  }),
  mounted() {
    this.getInfo();
    this.getCampaigns(1);
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY, HH:mm:ss a");
    },
  },
  watch: {
    startDate() {
      this.getCampaigns();
    },
    endDate() {
      this.getCampaigns();
    },
  },
  methods: {
    setActive(id) {
      this.active = id;
      this.getEntry(id);
    },
    getInfo() {
      const campaings = this.$store.getters["campaigns/listCampaigns"];
      for (const item of campaings) {
        if (item.uuid === this.$route.params.id) {
          this.name = item.name;
          this.type = types[item.type];
        }
      }
    },
    async exportAll() {
      this.loaded = false;
      try {
        const response = await this.$exportAll(this.$route.params.id, 1);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `campaign_data.csv`);
        link.click();
      } catch (e) {
        console.error(e);
      }
    },
    async getEntry(id) {
      this.loaded = false;
      try {
        const response = await this.$getInfo(this.$route.params.id, id);
        this.userInfo = response.data.data;
        this.loaded = true;
      } catch (e) {
        console.error(e);
      }
    },
    async getCampaigns() {
      this.loaded = false;
      try {
        const response = await this.$getAllUsers(
          this.$route.params.id,
          LuxonDateTime.fromISO(this.startDate).toMillis(),
          LuxonDateTime.fromISO(this.endDate).toMillis()
        );

        this.total = response.data.total;
        this.header = response.data.header;
        this.data = response.data.data;
        this.loaded = true;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
