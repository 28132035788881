import Vue from "vue";
import Router from "vue-router";
import Dashboard from "./components/pages/Dashboard";
import IndexPage from "./components/pages/IndexPage";
import EditCampaign from "./components/pages/EditCampaign";
import CreateCampaign from "./components/pages/CreateCampaign";
import CreateCampaignFrom from "./components/pages/CreateCampaignFrom";
import FormBuilderPage from "./components/pages/FormBuilderPage.vue";

import ListCampaigns from "./components/pages/ListCampaigns";
import CampaignStatsPage from "./components/pages/CampaignStatsPage";
import CampaignDataPage from "./components/pages/CampaignDataPage";
import CampaignIntegrationPage from "./components/pages/CampaignIntegrationPage";

import Login from "./components/auth/Login";
import Forgotpassword from "./components/auth/Forgotpassword";
import Resetpassword from "./components/auth/Resetpassword";
import ActivateAccount from "./components/auth/ActivateAccount";

import Register from "./components/auth/Register";
import RegisterValidate from "./components/auth/RegisterValidate";
import RegisterOnboarding from "./components/auth/RegisterOnboarding";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: IndexPage,
    },
    {
      path: "/dashboard/:id",
      name: "dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/create-campaign",
      name: "createcampaign",
      component: CreateCampaign,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/create/:id",
      name: "createfrom",
      component: CreateCampaignFrom,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/campaign/:id",
      name: "editcampaign",
      component: EditCampaign,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/campaign/formbuilder/:id",
      name: "FormBuilderPage",
      component: FormBuilderPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/campaign/stats/:id",
      name: "CampaignStats",
      component: CampaignStatsPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/campaign/data/:id",
      name: "CampaignDataPage",
      component: CampaignDataPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/campaign/integration/:id",
      name: "CampaignIntegrationPage",
      component: CampaignIntegrationPage,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/campaigns",
      name: "campaigns",
      component: ListCampaigns,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/forgot-password",
      name: "forgotpassword",
      component: Forgotpassword,
    },
    {
      path: "/resetpassword/:id",
      name: "resetpassword",
      component: Resetpassword,
    },
    {
      path: "/activate/:id",
      name: "activate",
      component: ActivateAccount,
    },


    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
    },
    {
      path: "/register/validate",
      name: "registerValidate",
      component: RegisterValidate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/register/onboarding",
      name: "registeronboarding",
      component: RegisterOnboarding,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("user") == null) {
      next({ name: "login" });
    } else {
      if (to.matched.some((record) => record.meta.is_admin)) {
        next({ name: "home" });
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("jwt") == null) {
      next();
    } else {
      next({ name: "home" });
    }
  } else {
    next();
  }
});
export default router;
