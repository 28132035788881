<template>
  <form class="register-form" @submit.prevent="validateCode">
    <p>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s, when an unknown printer took a galley of type and scrambled it to
      make a type specimen book. It has survived not only five centuries, but
      also the leap into electronic typesetting, remaining essentially
      unchanged. It was popularised in the 1960s with
    </p>
    <form-input
      :label="$t('EnterCampaignName')"
      v-model="values.campaignName"
      type="text"
      @validate="validate('campaignName')"
      name="code"
      :error="errors.campaignName"
    ></form-input>
    <form-input
      :label="$t('DomainLabel')"
      v-model="values.domain"
      type="text"
      @validate="validate('domain')"
      name="code"
      :error="errors.domain"
    ></form-input>

    <div class="row flex justify-content-end">
      <div class="col-3">
        <button class="btn ripple btn-main-primary btn-block">
          {{ $t("Continue") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { object, string } from "yup";
import FormInput from "../../common/form/FormInput";

export default {
  name: "RegisterStep1",
  components: {
    "form-input": FormInput,
  },
  data() {
    return {
      values: {
        campaignName: "",
        domain: "",
      },
      errors: {
        campaignName: "",
        domain: "",
      },
      loginFormSchema: object().shape({
        campaignName: string()
          .min(1, this.$t("ErrorMinLength", { c: 6 }))
          .max(100, this.$t("ErrorMaxLength", { c: 100 }))
          .required(this.$t("ErrorIsRequired")),
        domain: string()
          .url(this.$t("ErrorUrlIsNotValid"))
          .required(this.$t("ErrorIsRequired")),
      }),
    };
  },
  methods: {
    validateCode() {
      var self = this;

      this.loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async (e) => {
          const response = await self.$createCampaign(e);
          if (response.data.success === true) {
            // go to step 2
            self.$router.push("/dashboard");
          } else {
            self.$toast.error(self.$t(response.data.error), {
              position: "bottom-left",
            });
          }

          // self.$toast.error("your message");
        })
        .catch((err) => {
          console.log(err);
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    validate(field) {
      this.loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>

<style scoped>
</style>>
