<template>
  <div class="section pb-2xl-m">
    <div class="container mt-2xl mb-2xl">
      <div class="border-t mt-xl">
        <div class="row mt-xl v-l">
          <div class="column align-left">
            <div class="muted text-sm">
              2021 Republika Interactive SRL. All rights reserved.
            </div>
          </div>
          <div class="column align-right">
            <div class="row">
              <div class="muted text-sm mr-lg">
                Contact:<a href="mailto:info@anywebform.com" class="u mt-lg">info@anywebform.com</a>
              </div>
              <div><a href="#">Privacy Policy</a></div>
              <div><a href="#" class="ml-lg">Terms and Conditions</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>
