import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const messages = {
  en: {
    CreateAccount: "Create Account",
    FirstName: "First Name",
    LastName: "Last Name",
    userInactive:
      "The account is not active, please click on the link that you received on your email address",
    Email: "Email",
    Password: "Password",
    RetypePassword: "Retype Password",
    Continue: "Continue",
    EnterCodeValidate: "Enter Code",
    ErrorIsRequired: "The field is required",
    ErrorMinLength: "The field must be at least {c} characters",
    ErrorMaxLength: "The field must be maximum {c} characters",
    ErrorInvalidEmail: "The email address is not corect",
    ErrorPasswordMatch: "Passwords must match",
    userExits: "User exists",
    unableToFindUser: "Unable to find user",
    invalidPassword: "Invalid password",
    serverError: "Server Error",
    invalidToken: "Invalid token",
    invalidEmail: "Invalid Email",
    userNotFound: "User not found",
    tokenNotFound: "The code is not corect",
    EnterCampaignName: "Campaign Name",
    DomainLabel: "Domain",
    ErrorUrlIsNotValid: "Url is not valid",
    Send: "Send",
    ForgotPassword: "Forgot Password",
    ChangePassword: "Change Password",
    RecoverEmailSuccess: "A email has been sent to you",
    noPasswordPresent: "Please enter a password",
    noCodePresent: "The link is not valid",
    PasswordChanged: "Your password has been change.",
    Campaigns: "Campaigns",
    View: "View",
    ErrorLoadingCampaign: "Error Loading Campaign",
    CampaignUpdated: "Campaign Updated",
    FlowSaved: "Flow Saved",
    SignUpFree: "Sign Up Free",
    Login: "Login",
    HELLO: "HELLO",
    SomeInfo1: "It's used to identify the campaign in the admin",
    DomainInfo: "Where you want to place the script",
    AvatarInfo: "Avatar",
    SelectAField: "Plese select an element to see his properties",
    Pleaseaddsections: "Please add sections",
    Addsteps: "Add steps",
    Dragdropafieldtypefromtherighthere:
      " Drag & drop a field type from the right, here",
  },
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "es", // set fallback locale
  messages, // set locale messages
});

export default i18n;
